// * Header Component
import {
    AppBar,
    Box,
    Button,
    Typography,
    Stack,
    Toolbar,
    IconButton,
    Menu,
    MenuItem
} from "@mui/material"
import {
    AccountCircle as AccountCircleIcon,
    Menu as MenuIcon
} from "@mui/icons-material"
import { useAtom } from "jotai"
import { Auth as AmplifyAuth } from "aws-amplify"

import { emptyStringObject, isAuthor } from "../../utils"
import { dashboardsAtom, uiControlAtom, userAtom } from "../../states"
import Logo from "../../assets/images/logo.png"

const Header = () => {
    const [dashboards] = useAtom(dashboardsAtom)
    const [uiControl, setUiControl] = useAtom(uiControlAtom)
    const [user] = useAtom(userAtom)

    const handleDrawerToggle = () => {
        setUiControl({
            ...uiControl,
            menuOpened: !uiControl.menuOpened
        })
    }

    // * Loads summary(first) dashboard
    const handleHomeRedirect = () => {
        setUiControl({
            ...uiControl,
            incomingDashboard: {
                id: dashboards[0].id,
                mode: "change_dashboard"
            }
        })
    }

    const handleEditDashboard = () => {
        if (isAuthor(user.role)) {
            setUiControl({
                ...uiControl,
                accountDropdownAnchor: null,
                incomingDashboard: {
                    id: uiControl.currentDashboard.id,
                    mode: "start_authoring"
                }
            })
        }
    }

    const handleAccountDropdown = ({
        currentTarget: accountDropdownAnchor
    }: React.MouseEvent<HTMLElement>) => {
        setUiControl({
            ...uiControl,
            accountDropdownAnchor
        })
    }

    const handleAccountDropdownClose = () => {
        setUiControl({
            ...uiControl,
            accountDropdownAnchor: null
        })
    }

    const handleLogout = async () => {
        await AmplifyAuth.signOut()
    }

    const userDropdown = (
        <>
            <IconButton size="small" onClick={handleAccountDropdown}>
                <AccountCircleIcon />
            </IconButton>
            <Menu
                anchorEl={uiControl.accountDropdownAnchor}
                keepMounted
                open={Boolean(uiControl.accountDropdownAnchor)}
                onClose={handleAccountDropdownClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {isAuthor(user.role) && (
                    <MenuItem onClick={handleEditDashboard}>
                        Edit Dashboard
                    </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </>
    )

    return (
        <AppBar
            component="nav"
            elevation={0}
            sx={{ backgroundColor: "#FFFFFF" }}
        >
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    aria-label="menu"
                    onClick={handleDrawerToggle}
                    disabled={dashboards.length === 0}
                    sx={{
                        mr: 2
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Button
                    disabled={dashboards.length === 0}
                    onClick={handleHomeRedirect}
                    disableRipple={true}
                    disableElevation={true}
                    sx={{
                        "&:hover": {
                            background: "none"
                        }
                    }}
                >
                    <img
                        style={{ height: "30px" }}
                        src={Logo}
                        alt="Stats Logo"
                    />
                </Button>
                <Box sx={{ display: { xs: "none", sm: "block" }, flex: 1 }}>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                    >
                        {!emptyStringObject(user) && (
                            <>
                                <Typography
                                    variant="body1"
                                    color="#000000"
                                    component="span"
                                >
                                    Welcome,
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color="#000000"
                                    component="span"
                                    sx={{ fontWeight: "bold" }}
                                >
                                    {`${user.firstName} ${user.lastName}`}
                                </Typography>
                            </>
                        )}
                        {userDropdown}
                    </Stack>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default Header
